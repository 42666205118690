import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite"
import styles from './Cms.module.scss'
import LoadingSpinner from "../../components/loading/LoadingSpinner"
import MessagesService from "../../services/MessagesService";
import {Context} from "../../index";
import UserService from "../../services/UserService";
import TelegramService from "../../services/TelegramService";
import WriteTabelService from "../../services/WriteTabelService";

import * as XLSX from "xlsx"
import { saveAs } from "file-saver"


function Testing(){
    const {store} = useContext(Context)
    const [loading,setLoading] = useState(false)
    const [report ,setReport] = useState([])
    const sendMessage = async () => {
        try {
            setLoading(true)
            console.log('start sending...')
            const {data} = await MessagesService.sendNewMessage(308, 'Новое заявление на матерриальную помощь. Для просмотра перейдите в меню "Заявления -> Список заявлений"') // 308 - служба поддержки
            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const RemoveMonthT13 = async () => {
        try {
            setLoading(true)
            const {data} = await UserService.RemoveMonthT13('сентябрь',2024)
            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const ListenAgainist = async () => {
        try {
            setLoading(true)
            const {data} = await UserService.ListenAgainist()
            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const seeTelegramMe = async () => {
        try {
            setLoading(true)
            const {data} = await TelegramService.getMe()
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const sendTelegramMessage = async () => {

    }


        const exportToExcel = () => {
            // 1. Группируем по пользователям из TABLETABEL
            const groupedData = {};
            console.log('123')
            report.forEach((entry) => {
                const tabletabelId = entry.tabletabel_id;
                if (!groupedData[tabletabelId]) {
                    groupedData[tabletabelId] = {
                        tabletabel_name: entry.tabletabel_name,
                        tabletabel_developer: entry.tabletabel_developer,
                        days: {}
                    };
                }

                // 2. Записываем в дни имя из HISTORY + значение, если оно не пустое или не 0
                if (entry.value !== "" && entry.value !== null && entry.value !== 0) {
                    groupedData[tabletabelId].days[entry.day] = `${entry.user_full_name}:\n${entry.value}`;
                }
            });

            // 3. Формируем массив для XLSX
            const headers = ["ID", "Табель", "Разработчик", ...Array.from({ length: 31 }, (_, i) => `${i + 1}`)];
            const sheetData = [headers];

            Object.keys(groupedData).forEach((tabletabelId) => {
                const user = groupedData[tabletabelId];
                const row = [
                    tabletabelId,
                    user.tabletabel_name,
                    user.tabletabel_developer
                ];

                for (let day = 1; day <= 31; day++) {
                    row.push(user.days[day] || ""); // Если пусто, не записываем ничего
                }

                sheetData.push(row);
            });

            console.log(sheetData.length)
            // 4. Генерируем Excel
            const ws = XLSX.utils.aoa_to_sheet(sheetData);

            // 5. Устанавливаем стили (фиксируем нормальную работу wrapText)
            ws['!cols'] = headers.map(() => ({ wch: 15 })); // Ширина колонок

            if (!ws['!rows']) ws['!rows'] = [];
            for (let i = 0; i < sheetData.length; i++) {
                ws['!rows'][i] = { hpx: 20 }; // Устанавливаем высоту строки (чтобы текст нормально влезал)
            }

            // 6. Применяем стили к ячейкам (реальный фикс wrapText)
            Object.keys(ws).forEach(cell => {
                if (cell[0] === '!') return; // Пропускаем мета-данные
                ws[cell].s = {
                    font: { sz: 8 }, // Размер шрифта 8
                    alignment: { wrapText: true, vertical: "center", horizontal: "left" } // Перенос текста + выравнивание
                };
            });

            // 7. Создаём книгу и сохраняем файл
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Отчет");

            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
            console.log(sheetData.length)
            saveAs(dataBlob, "report.xlsx");
        }


    const getTabelHistory = async () => {
        try {
            setLoading(true)
            const {data} = await WriteTabelService.getHistory()
            setReport(data)

            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div className={styles.container}>
                <h1 style={{marginBottom:'10px'}}>Reports</h1>

                <div className={styles.testing}>
                    <div className={styles.buttons}>
                        <div onClick={getTabelHistory} className={styles.button}>Get Tabel History</div>
                        {report.length ? <div onClick={exportToExcel} className={styles.button}>! PRINT !</div> : null }
                    </div>
                </div>

                <h1 style={{marginBottom:'10px'}}>Testing</h1>
                <div className={styles.testing}>
                    <div className={styles.buttons}>
                        <div /*onClick={sendMessage}*/ className={styles.button}>send message</div>
                        <div /*onClick={RemoveMonthT13}*/ className={styles.button}>Remove Month 2024 from t13</div>
                        <div /*onClick={ListenAgainist}*/ className={styles.button}>See newly established</div>
                    </div>

                    <div className={styles.buttons}>
                        <div onClick={seeTelegramMe} className={styles.button}>seeMe telegram bot</div>
                        <div onClick={sendTelegramMessage} className={styles.button}>send test message to telegram</div>
                    </div>
                </div>


            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </>
    )
}
export default observer(Testing)