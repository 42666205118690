export default function shortenFileName(filename, maxLength = 40) {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1 || dotIndex === 0) return filename; // без расширения

    const name = filename.substring(0, dotIndex);
    const ext = filename.substring(dotIndex);

    if (filename.length <= maxLength) return filename;

    const allowedLength = maxLength - ext.length - 3; // учёт "..."
    const shortName = name.substring(0, allowedLength);

    return shortName + '...' + ext;
}