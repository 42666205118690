import style from './Galary.module.scss';
import React, {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useContext} from "react";
import {Context} from "../../index";
import ImgGallery from "./photogallery";

import formatDateTime from "../functions/formatDateTime";
import FilesService from "../../services/FilesService";
import {useRef} from "react";
import onDocument from "../functions/onDocument";
import SimpleInput from "../inputs/SimpleInput";
import SocialService from "../../services/SocialService";
import DocsAlbum from "../inputs/DocsAlbum";


export const EditCovers = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let getId = searchParams.get('id');

    const {store} = useContext(Context)
    const ava = store.user.avatar
    const myid = store.user.id
    const rule = store.user.unit
    const [currentImageIndex, setCurrentImageIndex] = useState(-1)
    const [thisalbum, setThisalbum] = useState(undefined)
    const [files, setFiles] = useState([])
    const empty = 0
    const [progress,setProgress] = useState(0)
    const [dragEnter,setDragEnter] = useState(false)
    const [dragEnterDocs,setDragEnterDocs] = useState(false)
    const filesInputRef = useRef({})
    const [loading,setLoading] = useState(false)
    const [arrimgs, setArrimgs] = useState([])
    const [arrdocs, setArrdocs] = useState([])
    const [listimgs, setListimgs] = useState([])
    const [filedoc, setFiledoc] = useState(null)
    const [groupdoc, setGroupdoc] = useState([])
    const [resultdocs, setResultdocs] = useState([])
    const [uploaddoc, setUploaddoc] = useState([])
    const [newcomment, setNewComment] = useState('')
    const [allcomment, setAllcomment] = useState([])
    const [colorlike, setColorlike] = useState('')

    const plusLike = async () => {
        try{
            const heart = await FilesService.plusLikeAlbum({myid, galary_id:getId})
            setThisalbum(heart.data)
            // images[currentImageIndex].user_id_likes = heart.data.user_id_likes
            // const newlikesarr = [...images]
            // setImages(newlikesarr)
        }catch(e){
            console.log(e)
        }
    }

    useEffect(()=>{
        if(thisalbum){
            if(thisalbum.user_id_likes.includes(myid)){
                setColorlike('red')
            }else{
                setColorlike('#454545')
            }
        }

    },[thisalbum])
    const sendComment = async () => {
        try {
            if(newcomment!==''){
                const post = await FilesService.sendCommGalary({newcomment, getId})
                console.log(post.data)
                setAllcomment(post.data)
                setNewComment('')
            }
        }catch(e){
            console.log(e)
        }
    }

    const allCommGalary = async () => {
        try {
            const comms = await FilesService.allCommGalary({getId})
            setAllcomment(comms.data)
            console.log(comms.data)
        }catch(e){
            console.log(e)
        }
    }

    useEffect(()=>{
        allCommGalary()
    },[])


    const viewAllDocs = async () => {
        try{
            const alldocs = await FilesService.DocsAlbum({getId})
            setResultdocs(alldocs.data)
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(()=>{
        viewAllDocs()
    },[uploaddoc])

    function dragEnterHandler(event){
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(true)
    }
    function dragLeaveHandler(event){
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(false)
    }
    function dropHandler(event){
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(false)
        selectFilesHandler(event,[...event.dataTransfer.files])
    }


    const handleUploadProgress = (progressEvent,name) => {
        const percentCompleted = (progressEvent.loaded / progressEvent.total)
        setProgress(prevState => ({
            ...prevState,
            [name]: percentCompleted,
        }))
    }

    const createToAlbum = async (imgs) => {
        try{
            if(imgs.length > 0){
                imgs.map(img => {
                    img.data= ''
                })
            }

            const listimgs = await FilesService.makeListImgs({imgs, getId})
            console.log(listimgs)
            setArrimgs(listimgs.data)
        }catch(e){

        }
    }

    const selectFilesHandler = async (e,drop = null) => {
        let selectedFiles
        if(drop===null){
            selectedFiles = Array.from(e.target.files)
        }
        else selectedFiles = drop
        setFiles(selectedFiles)
        let index = 0
        let imgs = []
        for (const file of selectedFiles) {
            try{
                setLoading(true)
                if(onDocument(file.name)){
                    const response = await FilesService.uploadGalaryFiles(file, (progressEvent) => handleUploadProgress(progressEvent, file.name))
                    if (response.data) {
                        console.log(response.data)
                        imgs.push(response.data)
                        // const newlist = [...arrimgs]
                        // newlist.push(response.data)
                        //
                        // setArrimgs(newlist)
                        // console.log(arrimgs)
                    }
                }else{
                    const errFiles = [...selectedFiles]
                    errFiles[index].err = 'Неподдерживаемый фомат файла'
                    setFiles(errFiles)
                }
            }catch (e){
                console.log(e+': Проблема загрузки списка документов')
            }finally {
                setLoading(false)
            }
            index++
        }
        setArrimgs(imgs)
        createToAlbum(imgs)
    }

    const getListImgs = async () => {
        try {
            const list = await FilesService.getListFromAlbum({getId})
            setListimgs(list.data)
        }catch (e){

        }
    }

    useEffect(()=>{
        getListImgs()
        console.log('перезагрузка')
    }, [arrimgs])

    const getAlbum = async () => {
        try{
            const thisalbum = await FilesService.getAlbum({getId})
            console.log(thisalbum.data)
            setThisalbum(thisalbum.data)
        }catch (e) {
            console.log(e)
        }
    }


    const uploadDocs = async (docs) => {
        try{
            let fileslist = []
            docs.forEach(file=>{
                fileslist.push(file.name)
            })
            console.log(fileslist)
            const newfiles = await FilesService.sendDocsToGalary({getId, fileslist})
            console.log(newfiles.data)
            setUploaddoc(newfiles.data)
            setFiledoc(null)
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(()=>{
        getAlbum()
    }, [])

    useEffect(()=>{
        console.log(filedoc)
    }, [filedoc])

    return (
        <div className={style.galaryediter}>
            {listimgs && currentImageIndex>=0 ? <ImgGallery images={listimgs} setImages={setListimgs} path={''} currentImageIndex={currentImageIndex} setCurrentImageIndex={setCurrentImageIndex}/> : null}
            <Link className="back-button" to="/galary"><i className="fa-solid fa-arrow-left"></i>Назад</Link>
            <div className={style.namegalary}>{(thisalbum)?thisalbum.title:''}</div>
            <span className="news-status"><span className="rigth-box"><span className="circle cliker " onClick={()=>plusLike()}><i className="fa-regular fa-heart" style={{color: `${colorlike}`}}></i>{(thisalbum)&&thisalbum.user_id_likes.length}</span></span></span>
            <br/>
            <div className={style.galarylist}>
                {(rule === 99 || store.user.account==='superadmin')&&(<div className={style.plusimgitem}
                     onClick={(e) => filesInputRef.current.click()} onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler} onDragOver={dragEnterHandler} onDrop={dropHandler}
                >
                    <input onChange={(e) => selectFilesHandler(e)} multiple ref={filesInputRef} className='hidden-upload' type='file'/>
                    <i className="fa-solid fa-plus"/>
                </div>)}
                {(listimgs.length > 0)&&listimgs.map((cover, index) => (
                    <div className={style.galleryitem} key={index} onClick={()=>setCurrentImageIndex(index)}>
                        <img src={`files/albums/${cover.image}`} alt={cover.image || 'Image'} />
                        <div className={style.coverboard}><i className="fa-solid fa-eye"/></div>
                    </div>
                ))}

            </div>
            <div className={style.descriptiongalary}>
                {(thisalbum)?thisalbum.description:''}
            </div>
{/*            <div className={style.namegalary}>Приложения</div>
            <div className={style.filesofgalary}>
                {(resultdocs.length > 0)&&resultdocs.map((doc, index)=>(
                    <div className={style.filesofgalary_block} key={index}>
                        <i className="fa-regular fa-file"/>
                        <div className={style.filesofgalary_block_name}>{doc.doc.substring(13)}</div>
                    </div>
                ))}
            </div>*/}
            {(rule === 99 || store.user.account==='superadmin')&&(<div className={style.pluscenter}>
                {(filedoc != null)&&(<i className="fa-solid fa-plus" onClick={()=>uploadDocs(filedoc)}/>)}
                <DocsAlbum files={filedoc} setFiles={setFiledoc}/>
            </div>)}


            <div className={`history_messs`} style={{minWidth: '100%'}}>
                    <div className={`title`}>Комментарии</div>
                    <div className="history_mess_pen" >
                        <textarea className="history_mess_pen_letter" id='textmess'
                                  value={newcomment} onChange={(e)=>setNewComment(e.target.value)}
                        />

                        <div className="history_mess_pen_btn" onClick={()=>sendComment()}>Отправить<i className="fa-regular fa-paper-plane"/></div>
                    </div>

                    <div className="history_mess_list">
                        {allcomment.map((comm, index)=>(
                            <div key={index} className="history_mess_list_block ">
                                <div className="history_mess_list_block_ava" style={{backgroundImage: `url(/files/profile/${comm.user.avatar})`}}></div>
                                <div className="history_mess_list_block_content" >
                                    <div className="history_mess_list_block_content_name" >
                                        <p>
                                            {comm.user.full_name}
                                        </p>
                                        {/*<p className={`icons`} >*/}
                                        {/*    <>*/}
                                        {/*        <i className="fa-solid fa-pencil small"></i>*/}
                                        {/*    </>*/}
                                        {/*    <i className="fa-solid fa-xmark"></i>*/}

                                        {/*</p>*/}
                                    </div>
                                    <div className="history_mess_list_block_content_dateandstatus" >
                                        <div>{comm.text}</div>
                                        <div className="history_mess_list_block_content_date" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

        </div>

    )
}
