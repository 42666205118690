import React, {useContext, useEffect, useMemo, useRef, useState} from "react"
import {observer} from "mobx-react-lite"
import s from "./MtrPage.module.scss"
import LoadingSpinner from "../../components/loading/LoadingSpinner"
import OdataService from "../../services/OdataService";
import {DatePicker} from "rsuite";
import formatDate from "../../components/functions/formatDate";
import downloadXlsxFromJson from "./DownloadXlsx";

function MtrPage(){
    const [loading,setLoading] = useState(false)
    const [documents,setDocuments] = useState([])
    const [filteredDocuments, setFilteredDocuments] = useState([])
    const [dateFrom,setDateFrom] = useState()
    const [dateTo,setDateTo] = useState()
    const [sortField, setSortField] = useState(null)
    const [sortOrder, setSortOrder] = useState("asc")

    const loadingHandler = async () => {
        try {
            setLoading(true)

            const {data} = await OdataService.getDocMngOdata()
            const users = await OdataService.getDocUsers()
            const documents = await OdataService.getDocuments()
            const vizas = await OdataService.getVizas()
            const struct = await OdataService.getFileStructure()

            // const commentResponse = await OdataService.getCommHistory('text')
            // console.log(commentResponse.data.value)

            const documentsWithUsers = await Promise.all(
                documents.data.value
                .filter(doc => !doc.DeletionMark) // Исключаем удаленные документы
                .map(async doc => {
                    // Ищем пользователя по Ref_Key
                    const user = users.data.value.find(u => u.Ref_Key === doc.Создал_Key);

                    // Фильтруем визы, относящиеся к этому документу
                    const docVizas = vizas.data.value
                        .filter(viza => viza.Документ === doc.Ref_Key) // Только визы этого документа
                        .map(viza => ({
                            ...viza,
                            // Заменяем Исполнитель на имя пользователя
                            Исполнитель: users.data.value.find(u => u.Ref_Key === viza.Исполнитель)?.Description || "Неизвестный исполнитель"
                        }))

                    // Определяем результат согласования
                    let РезультатСогласования = "Все согласовали";
                    let Несогласовавший = null;
                    let Комментарий = null;

                    for (const viza of docVizas) {
                        if (viza.РезультатСогласования !== 'Согласовано' && viza.РезультатСогласования !== 'СогласованоСЗамечаниями') { // Проверяем, есть ли пустой результат
                            РезультатСогласования = viza.РезультатСогласования ? viza.РезультатСогласования : "Не согласовано";
                            Несогласовавший = viza.Исполнитель;
                            Комментарий = viza.Комментарий;
                            break; // Как только нашли несогласованную визу — выходим из цикла
                        }
                    }
                    const filesResponse = await OdataService.getFiles(doc.Ref_Key)
                    const docFiles = filesResponse.data.value || []

                    const processResponse = await OdataService.getProceses(doc.Ref_Key);
                    // ✅ Сначала добавляем fio в исполнителей
                    let processesWithFio = await Promise.all(
                        processResponse.data.value.map(async (proc) => {
                            let updatedExecutors = await Promise.all(
                                proc.Исполнители.map(async (exec) => {
                                    const fio = users.data.value.find((u) => u.Ref_Key === exec.Исполнитель)?.Description || "Неизвестный исполнитель";

                                    // ✅ Делаем запрос к истории процесса
                                    const historyResponse = await OdataService.getProcessHistory(exec.Ref_Key);
                                    const commentResponse = await OdataService.getCommHistory(exec.Ref_Key);

                                    const processHistory = historyResponse.data.value || [];

                                    const commentHistoryRaw = commentResponse.data.value || [];
                                    const commentHistory = commentHistoryRaw.map((comment) => {
                                        const authorFio = users.data.value.find((u) => u.Ref_Key === comment.Исполнитель_Key)?.Description || "Неизвестный исполнитель";
                                        return {
                                            ...comment,
                                            fio: authorFio,
                                        };
                                    });

                                    return {
                                        ...exec,
                                        fio,
                                        history: processHistory.length ? processHistory : null, // Добавляем историю, если есть
                                        comments: commentHistory.length ? commentHistory : null, // Добавляем коменты, если есть
                                    };
                                })
                            );

                            return {
                                ...proc,
                                Исполнители: updatedExecutors,
                            };
                        })
                    );

                    // ✅ Потом фильтруем процессы, убирая те, где есть "Амирова Альмира Равильевна"
                    const processes = processesWithFio.filter(proc =>
                        !proc.Исполнители.some(exec => exec.fio === "Амирова Альмира Равильевна" || exec.fio === "Подзолкова Александра Олеговна")
                    );

                    return {
                        ...doc,
                        Создал_Key: user ? user.Description : "Неизвестный пользователь",
                        vizas: docVizas,
                        files: docFiles,
                        result: РезультатСогласования,
                        stop: Несогласовавший,
                        sog_comm: Комментарий,
                        processes,
                    };
                }))

            setDocuments(documentsWithUsers)
            console.log(documentsWithUsers)
            // console.log(struct)
            // console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const handleSort = (field) => {
        if (sortField === field) {
            // Если сортируем по тому же полю — меняем порядок
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            // Если выбрано новое поле — сбрасываем порядок на "asc"
            setSortField(field);
            setSortOrder("asc");
        }
    }

    useEffect(() => {
        if (!dateFrom && !dateTo) {
            setFilteredDocuments(documents); // Если нет диапазона, берём все
            return;
        }
        const filtered = documents.filter(doc => {
            const docDate = new Date(doc.ДатаСоздания); // Преобразуем дату документа

            if (dateFrom && !dateTo) {
                return docDate >= new Date(dateFrom); // Фильтруем только "от"
            }

            if (!dateFrom && dateTo) {
                return docDate <= new Date(dateTo); // Фильтруем только "до"
            }

            return docDate >= new Date(dateFrom) && docDate <= new Date(dateTo); // Фильтруем диапазон
        })

        setFilteredDocuments(filtered);
    }, [dateFrom, dateTo, documents]);
    useEffect(()=>{
        loadingHandler()
    }, [])

    const sortedDocuments = useMemo(() => {
        if (!sortField) return filteredDocuments; // Если сортировки нет, просто возвращаем массив

        return [...filteredDocuments].sort((a, b) => {
            let valA = a[sortField];
            let valB = b[sortField];

            // Фиксим сравнение строк (без учёта регистра)
            if (typeof valA === "string") valA = valA.toLowerCase();
            if (typeof valB === "string") valB = valB.toLowerCase();

            // Фиксим сортировку дат
            if (sortField === "ДатаСоздания") {
                valA = new Date(valA);
                valB = new Date(valB);
            }

            // Определяем направление сортировки
            return sortOrder === "asc" ? (valA > valB ? 1 : -1) : (valA < valB ? 1 : -1);
        });
    }, [sortField, sortOrder, filteredDocuments])

    const downloadXlsx = () => {
        downloadXlsxFromJson(sortedDocuments)
    }
    function isActionToExecute(description) {
        return description.trim().toLowerCase().startsWith("исполнить");
    }
    function formatFioToShort(fio) {
        if (!fio) return "";

        const cleaned = fio.trim();
        const parts = cleaned.split(/\s+/);
        const surname = parts[0];

        // Оставляем как есть, если уже в формате "Фамилия И.О."
        if (/^[А-ЯЁ][а-яё-]+ [А-ЯЁ]\.[А-ЯЁ]\.?$/u.test(cleaned)) {
            return cleaned;
        }

        const initials = parts.slice(1, 3) // берём максимум два
            .map(p => p[0]?.toUpperCase() + ".")
            .join("");

        return `${surname} ${initials}`;
    }
    return (
        <div className={s.container}>
            <div className={s.title}> Выберите временной интервал (не обязательно)</div>
            <DatePicker
                value={dateFrom ? new Date(dateFrom) : null}
                onChange={e => setDateFrom(e ? e.toISOString().split("T")[0] : "")}
            />
            <DatePicker
                value={dateTo ? new Date(dateTo) : null}
                onChange={e => setDateTo(e ? e.toISOString().split("T")[0] : "")}
            />

            <div onClick={downloadXlsx} className={s.button}>Скачать XLSX</div>
            <div className={s.sorts}>
                <div className={s.sort} onClick={() => handleSort("ДатаСоздания")}>
                    По дате {sortField === "ДатаСоздания" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                </div>
                <div className={s.sort} onClick={() => handleSort("Description")}>
                    По имени {sortField === "Description" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                </div>
                <div className={s.sort} onClick={() => handleSort("Создал_Key")}>
                    По автору {sortField === "Создал_Key" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                </div>
                <div className={s.sort} onClick={() => handleSort("result")}>
                    По статусу {sortField === "result" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                </div>
            </div>
            <div className={s.tableContainer}>
                <div className={s.table}>
                    <div className={s.head}>
                        <div className={`${s.td} ${s.small}`}>№</div>
                        <div className={s.td}>Имя</div>
                        <div className={`${s.td} ${s.small2}`}>Создана</div>
                        <div className={`${s.td} ${s.small2}`}>Автор</div>
                        <div className={s.td}>Визы</div>
                        <div className={s.td}>Исполнители</div>
                        <div className={s.td}>Выполнено</div>
                        <div className={s.td}>В работе</div>
                        <div className={s.td}>Контроль</div>
                        {/*<div className={`${s.td} ${s.small}`}>Файлы</div>*/}
                    </div>
                    {sortedDocuments.length ? sortedDocuments.map( (doc,key) => (
                        <div key={key} className={`${s.tr} ${key % 2 === 0 ? s.even : s.odd}`}>
                            <div className={`${s.td} ${s.small}`}>{key+1}</div>
                            <div className={s.td}>{doc.Description}</div>
                            <div className={`${s.td} ${s.small2}`}>{formatDate(doc.ДатаСоздания)}</div>
                            <div className={`${s.td} ${s.small2}`}>{formatFioToShort(doc.Создал_Key)}</div>
                            <div className={s.td}><p className={s.important}>{doc.vizas.length} </p><p className={s.important}>{doc.vizas.length ? doc.result : 'Пустой маршрут'}</p><p>{doc.stop}</p> <p>{doc.sog_comm}</p></div>
                            <div className={s.td}>{doc.processes.length ? doc.processes[0].Исполнители.map((ex,key2) => (
                                <p key={key2}>{ex.fio}</p>
                            )) : null}</div>
                            <div className={s.td}>
                                <p>{doc.processes.length ?
                                    <span>{doc.processes[0].Исполнители[0] ? doc.processes[0].Исполнители[0].history ? doc.processes[0].Исполнители[0].history[0].Описание : null : null}</span>
                                    : null}</p>
                            </div>
                            <div className={s.td}>
                                <p>
                                    {doc.processes.length ?
                                    <>
                                    {doc.processes[0].Исполнители[0] ? doc.processes[0].Исполнители[0].comments ?
                                        <> { doc.processes[0].Исполнители[0].comments.map( (comment,comkey) => (
                                            <>
                                                {!comment.Executed && isActionToExecute(comment.Description) ? <p>{`${comment.fio}. ${comment.РезультатВыполнения.length ? comment.РезультатВыполнения : 'без комментария'}`}</p> : null}
                                            </>
                                        )  ) } </>  : null : null}
                                    </>
                                    : null}
                                </p>
                            </div>
                            <div className={s.td}>
                                <p>
                                    {doc.processes.length ?
                                        <>
                                            {doc.processes[0].Исполнители[0] ? doc.processes[0].Исполнители[0].comments ?
                                                <> { doc.processes[0].Исполнители[0].comments.map( comment => (
                                                    <>
                                                        {!isActionToExecute(comment.Description) ?
                                                            <>
                                                                <p>{`${comment.fio}. ${comment.РезультатВыполнения.length ? comment.РезультатВыполнения : 'без комментария'}`}</p>
                                                                <p>{comment.Executed ? 'Контроль пройден' : 'Контроль не пройден или заявка не исполнена'}</p>
                                                        </> : null}
                                                    </>
                                                )  ) } </>  : null : null}
                                        </>
                                        : null}
                                </p>
                            </div>
                            {/*<div className={`${s.td} ${s.small}`}><p className={s.important}>{doc.files.length}</p></div>*/}
                        </div>
                    )): null}
                </div>
            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}

export default observer(MtrPage)