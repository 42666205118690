import "./crews.scss"
import CmsDatePicket from "../../inputs/CmsDatePicket";
import {useState} from "react";
import {useContext, useEffect} from "react";
import {Context} from "../../../index";
import React,{useRef} from "react";
import WeldingService from "../../../services/WeldingService";
import UserService from "../../../services/UserService";
import MultiSelect from "../../inputs/MultiSelect";
import CheckBox from "../../inputs/CheckBox";
import FileInput from "../../inputs/FileInput";
import {useMessage} from "../../../hooks/message.hook";
import ModalFiles from "../../modalwin/ModalFiles";
import ModalForTable from "../../modalwin/ModalForTable";
import CmsSelect from "../../inputs/CmsSelect";
import LoadingSpinner from "../../loading/LoadingSpinner";
import CircularProgress from "../../OldComponents/old/CircularProgress";
import onDocument from "../../functions/onDocument";
import FilesService from "../../../services/FilesService";
import shortenText from "../../functions/shortenText";
import shortenFileName from "../../functions/ShortenFileName";
import SocialService from "../../../services/SocialService";

export const NewCrewS = () => {
    const [loading,setLoading] = useState(false)

    const filesInputRef = useRef(null)

    const [datein, setDatein] = useState()
    const [dateto, setDateto] = useState()
    const [active, setActive] = useState(false)
    const [activeDelete, setActiveDelete] = useState(false)
    const [indexDelete, setIndexDelete] = useState(-1)
    const [listMans, setListMans] = useState([])
    const [listCrew, setListCrew] = useState([])
    const [openblock, setOpenblock] = useState(false)
    const [thisMans, setThisMans] = useState([])
    const [empty,setEmpty] = useState([])
    const [thisDev, setThisDev] = useState([])

    const [name,setName] = useState('')
    const [num,setNum] = useState('')
    const [about,setAbout] = useState('')
    const [groupForNew,setGroupForNew] = useState([])
    const [group,setGroup] = useState([])
    const [oldgroup,setOldGroup] = useState([])
    const [docs,setDocs] = useState([])

    const [selectedCrew,setSelectedCrew] = useState(-1)
    const [crewMans,setCrewMans] = useState([])
    const [crewData,setCrewData] = useState([])
    const [onChangeCrewMans,setOnChangeCrewMans] = useState(false)

    const [ifChangeName,setIfChangeName] = useState(false)
    const [crewNameInp,setCrewNameInp] = useState('')
    const [crewCommInp,setCrewCommInp] = useState('')

    const [onDocuments,setOnDocuments] = useState(false)

    const [activeFolder,setActiveFolder] = useState(false)

    const [files,setFiles] = useState([])
    const [tempFile,setTempFile] = useState()

    const {store} = useContext(Context)
    const message = useMessage()

    const loadingHandler = async () => {
        try {
            setLoading(true)
            const {data} = await UserService.getWorkers()
            const crews = await WeldingService.getCrew()
            if(data && crews.data){
                setListMans(data)
                setListCrew(crews.data)
            }
        } catch(e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const onDocumentsHandler = () => {
        setOnDocuments(!onDocuments)
    }
    useEffect(() => {
        loadingHandler()
    }, [])
    useEffect(() => {
        if(selectedCrew>=0){
            loadCrewHandler()
        }
    },[selectedCrew])
    useEffect(() => {
        if (JSON.stringify(group) === JSON.stringify(oldgroup)) {
            setOnChangeCrewMans(false)
        } else {
            setOnChangeCrewMans(true)
        }
    },[group])
    const checkEmpty = () => {
        const n = [...empty]

        n[0] = !!!name.trim().length
        n[1] = !!!num.trim().length
        n[2] = !!!about.trim().length
        n[3] = !!!groupForNew.length

        const hasTrueValue = n.some(value => value === true)
        if( hasTrueValue ) setEmpty(n)
        else setEmpty([])

        return !hasTrueValue
    }
    const setEmptyStates = () => {
        setName('')
        setNum('')
        setAbout('')
        setGroupForNew([])
        setDocs([])
        setOpenblock(false)
    }
    const loadCrewHandler = async () => {
        try{
            setLoading(true)
            if(selectedCrew >= 0){
                const {data} = await WeldingService.loadMansToCrew(listCrew[selectedCrew].id)
                const crewDataNet = await WeldingService.loadCrewData(listCrew[selectedCrew].id)
                if(data && crewDataNet.data){
                    setCrewMans(data)
                    console.log(crewDataNet)
                    setCrewData(crewDataNet.data)
                    setCrewNameInp(crewDataNet.data.crewname)
                    setCrewCommInp(crewDataNet.data.comment)
                    const selectedGroup = []
                    listMans.map(item => {
                        data.map(row => {
                          if(row.humanlist) if (row.humanlist.tn === item.tn) selectedGroup.push(item)
                          if(row.t13uni) if (row.t13uni.tn === item.tn) selectedGroup.push(item)
                        })
                    })
                    setGroup(selectedGroup.map(item => ({ ...item })))
                    setOldGroup(selectedGroup.map(item => ({ ...item })))
                }
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const selectCrewHandler = (index) => {
        setActive(!active)
        setSelectedCrew(index)
    }
    const createHandler = async () => {
        try {
            setLoading(true)
            if(checkEmpty()){
                const crew = {crewname:name,totalmans:num,comment:about,inn:store.user.inn}
                const {data} = await WeldingService.createNewCrew(crew,groupForNew)
                if(data){
                    setListCrew([...listCrew,data])
                    setEmptyStates()
                    message('Бригада добавлена')
                }
            }else {
                message('Заполните необходимые данные')
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const delmanHandler = (index) => {
        const newGroup = [...group]
        newGroup.splice(index,1)
        setGroup(newGroup)

    }
    const ifChangeCrew = async () => {
        setIfChangeName(!ifChangeName)
        if( (crewCommInp !== crewData.comment || crewNameInp !== crewData.crewname) && ifChangeName){
            const { data } = await WeldingService.saveCrewData(crewData.id,crewNameInp, crewCommInp)
            loadingHandler()
            loadCrewHandler()
        }
    }
    const changeCrewName = (e) => {
        setCrewNameInp(e.target.value)
    }
    const changeCrewComment = (e) => {
        setCrewCommInp(e.target.value)
    }
    const cancelHandler = () => {
        setActive(false)
        setSelectedCrew(-1)
        setActiveFolder(false)
        setIfChangeName(false)
        setActiveDelete(false)
        setIndexDelete(-1)
    }
    const saveCrewHandler = async() => {
        try {
            if(onChangeCrewMans){
                const {data} = await WeldingService.saveCrewMans(crewData.id,group)
                if(data) message('Список изменен')
                else message('Ошибка изменения списка')
                cancelHandler()
            }
        }catch (e) {
            console.log(e)
        }
    }
    const trashCrewHandler = (index) => {
        setIndexDelete(index)
        setActiveDelete(true)
    }
    const trashCrew = async () => {
        try{
            setLoading(true)
            const {data} = await WeldingService.TrashCrew(listCrew[indexDelete].id)
            data ? setListCrew(prev => prev.filter(item => item.id !== listCrew[indexDelete].id)) : message('Ошибка удаления')
            setActiveDelete(false)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const openFolderHandler = async (index) => {
        try{
            setActiveFolder(true)
            setSelectedCrew(listCrew[index])
            console.log(listCrew[index])
            await getCrewFiles(listCrew[index].id)
        }catch (e) {
            console.log(e)
        }
    }

    const getCrewFiles = async (id) => {
        try {
            setLoading(true)
            const {data} = await FilesService.getCrewFiles(id)
            console.log(data)
            setFiles(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const selectFilesHandler = async (e) => {
        const selectedFile = e.target.files[0]
        try{
            setLoading(true)
            if(onDocument(selectedFile.name)){
                const {data} = await FilesService.uploadCrewFile(selectedFile,selectedCrew)
                if (data) {
                    setFiles(data)
                }
            }else{
                message('Неподдерживаемый фомат файла')
            }
        }catch (e){
            console.log(e+': Проблема загрузки документа, обратитесь в службу поддержки')
            message('Слишком большой файл, или не правильная структура файла')
        }
        finally {
            setLoading(false)
        }
    }
    const deleteCrewFileHandler = async (file) => {
        try{
            if(selectedCrew){
                const {data} = await FilesService.deleteCrewFile(file,selectedCrew.id)
                if(data){
                    setFiles(data)
                }
            }else message('Что то пошло не так')
        }catch (e) {
            console.log(e+': Проблема удаления')
        }
    }
    const handleDownload = async (file) => {
        try {
            console.log(file)
            const response = await FilesService.downloadCrewFile(file)

            if(response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file.name)
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(url)
            }
        } catch (e) {
            console.log(e)
        }
    }
    function DeleteCrew () {
        return (
            <div className={'delete-window'}>
                <p>Вы действиетльно желаете удалить бригаду { indexDelete>0 ? listCrew[indexDelete].name : null }?</p>
                <div className={'buttons'}>
                    <button onClick={trashCrew} className={'da'}>Удалить</button>
                    <button onClick={cancelHandler} className={'net'}>Нет</button>
                </div>
            </div>
        )
    }
    function ViewCrew(){
        return (
            <div className={'modal_crew_view'} style={{overflowY: 'auto'}}>

                {!ifChangeName ? <div className={'title'}>{crewNameInp}</div> : <div className={'input'}><input onChange={(e) => changeCrewName(e)} value={crewNameInp} placeholder={'Имя Бригады'}/></div> }
                {!ifChangeName ? <div className={`p`}>{crewCommInp}</div> : <div className={'input'}><input onChange={(e) => changeCrewComment(e)} value={crewCommInp} placeholder={'Шифр Бригады'}/></div> }
                <div onClick={() => ifChangeCrew()} className={'ifchange'}>{ifChangeName ? 'сохранить' : 'изменить' }</div>
                <div className={`pm`}>Среднее количество работников: {crewData.totalmans}</div>

                <div className={`view-select`}>
                    <MultiSelect options={listMans} setOptions={setGroup} values={group} />
                </div>
                <div className={`view_crew`} style={{fontSize: '10pt'}}>
                    {group.length ? group.map((item,index) => (
                        <div key={index} className="view_crew_row">
                            <div className="view_crew_colimn s1">{index+1}</div>
                            <div className="view_crew_colimn s2">{item.name}</div>
                            <div className="view_crew_colimn s3">{item.developer}</div>
                            {/*<div className="view_crew_colimn c4">{item.tn}</div>*/}
                            <div className="view_crew_colimn s5"><i onClick={(e) => delmanHandler(index)} className="fa-solid fa-xmark"></i></div>
                        </div>
                    )) : ' Люди в данной бригаде отсутствуют'}
                </div>

                <div className={`buttons view_crew_btn`}>
                    <div onClick={(e) => saveCrewHandler()} className={`button ${!onChangeCrewMans && 'noactive'}`}>Сохранить</div>
                    <div onClick={(e) => cancelHandler()} className={`button`}>Отменить</div>
                </div>
            </div>
        )
    }
    function FolderCrew(){
        return (
            <div className={'modal_crew_view modal_crew_files'} style={{overflowY: 'auto'}}>
                <h3 className={'title_crew'}>{selectedCrew ? selectedCrew.crewname : null}</h3>
                {files ?
                <div className="file-table">
                    <div className={'table-wrapper'}>
                        <table>
                            <thead>
                            <tr>
                                <th>Имя</th>
                                <th>Тип</th>
                                <th>Скачать</th>
                                <th>Удалить</th>
                            </tr>
                            </thead>
                            <tbody>
                            {files.map((file, index) => (
                                <tr key={index}>
                                    <td>{shortenFileName(file.name,40)}</td>
                                    <td>{file.type}</td>
                                    <td className={'ctr'}>
                                        <button onClick={() => handleDownload(file)}> <i className="fa-solid fa-file-import"></i></button>
                                    </td>
                                    <td className={'ctr'}>
                                        <button onClick={() => deleteCrewFileHandler(file)} className="delete"><i className="fa-solid fa-trash"></i></button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                    : null }
                <div className={`buttons_files view_crew_btn`}>
                    <input onChange={(e) => selectFilesHandler(e)} ref={filesInputRef} className='hidden-upload' type='file'/>
                    <button className="button temp_files_set" onClick={(e) => filesInputRef.current.click()}> Загрузить <i className="fa-solid fa-file-import"></i> </button>
                    <button onClick={cancelHandler} className={`button`}>Выйти</button>
                </div>
            </div>
        )
    }

    return (
       <div className="newcrew">
           <div className="newcrew_title">Список Звеньв и Бригад</div>
           <div className="newcrew_btn" onClick={()=>setOpenblock(!openblock)}>{!openblock ? 'Добавить звено / бригаду' : 'Скрыть панель'}</div>
           <div className="newcrew_block" style={(openblock)?{display: 'flex'}:{display: 'none'}}>
               <div className="newcrew_block_inputns">
                   <input value={name} onChange={(e) => setName(e.target.value)} className={`newcrew_block_inpt pul-inpt ${empty[0] && 'red-solid-border'}`} placeholder="Введите название команды"/>
                   <input value={num} onChange={(e) => setNum(e.target.value)} className={`newcrew_block_inpt pul-inpt ${empty[1] && 'red-solid-border'}`} placeholder="Ср. кол-во человек"/>
                   <input value={about} onChange={(e) => setAbout(e.target.value)} className={`newcrew_block_inpt ${empty[2] && 'red-solid-border'}`} placeholder="Краткое описание"/>
               </div>
               <div className="slash"></div>

               <div className="newcrew_block_title">Добавить сотрудника</div>
               <div className="newcrew_block_selects">
                    <MultiSelect options={listMans} setOptions={setGroupForNew} values={groupForNew} empty={empty[3]} />
               </div>
               <div className="slash"></div>

               <CheckBox label={'Добавить документы'} disable={true} checked={onDocuments} onChange={onDocumentsHandler}/>
               {onDocuments &&
               <div className="newcrew_block_plusman">
                   <div className="newcrew_block_plusman_left">
                       <input className="newcrew_block_inpt" placeholder="Краткое описание"/>
                       <div className="newcrew_block_plusman_right_picker">
                           <CmsDatePicket placeholder={'начало'} onChange={setDatein} size={'40%'}/>
                           <span style={{width:'20px'}}></span>
                           <CmsDatePicket placeholder={'окончание'} onChange={setDateto} size={'40%'}/>
                       </div>
                       <div className={'newcrew_block_selects'}>
                           <FileInput files={docs} setFiles={setDocs} user_id={store.user.id} empty={empty[6]}/>
                       </div>
                   </div>
               </div>}
               <div className={`buttons`}>
                   <div onClick={(e) => createHandler()} className={`button`}>Добавить</div>
               </div>
           </div>

           <div className="newcrew_list">
               <div className="newcrew_list_strs">
                   {listCrew.map((crew, index)=>(
                       <div key={index} className="newcrew_list_strock">
                           <div className="newcrew_list_strock_pp">{index+1}</div>
                           <div className="newcrew_list_strock_name">{crew.crewname}</div>
                           <div className="newcrew_list_strock_num">{crew.comment}</div>
                           <div className="newcrew_list_strock_open" onClick={()=>selectCrewHandler(index)}>Открыть</div>
                           <div className="newcrew_list_strock_delete" onClick={()=>trashCrewHandler(index)}><i className={'fa-solid fa-trash-can'}/></div>
                           <div className="newcrew_list_strock_delete" onClick={()=>openFolderHandler(index)}><i className={'fa-regular fa-folder-open'}/></div>
                       </div>
                   ))}
               </div>
           </div>

           <ModalForTable  heigth = {'90vh'} width = {'100vw'} data={ViewCrew()} active={active} setActive={setActive} scroll={'auto'}/>
           <ModalFiles heigth={'100px'} data={DeleteCrew()} active={activeDelete} setActive={setActiveDelete} scroll={'auto'}/>
           <ModalFiles heigth={'auto'} data={FolderCrew()} active={activeFolder} setActive={setActiveFolder} scroll={'auto'}/>
           {loading ? (<LoadingSpinner zindex="1000000"/>) : null}
       </div>
    )

}