import React from "react"

import {MainHeader} from "../../components/header/Mainheader"
import {WorkPage} from "../../components/workpage/WorkPage"
import {Mainnavbar} from "../../components/navbar/Mainnavbar"
import MtrPage from "./MtrPage";


export default function OmtsRouter({page= 1}){
    return (
        <div className='new_container'>
            <div className="up_path"><MainHeader /></div>
            <div className="main_path">
                <Mainnavbar />
                {page === 1 && <WorkPage data={<MtrPage />}/>}

            </div>
        </div>
    )
}
