import React from "react";
import { saveAs } from "file-saver";
import formatDate from "../../components/functions/formatDate";
import ExcelJS from "exceljs";

function isActionToExecute(description) {
    return description.trim().toLowerCase().startsWith("исполнить");
}

const downloadXlsxFromJson = async (json) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Документы");

    // 💡 Устанавливаем стили для заголовков
    const headerStyle = {
        font: { bold: true, size: 10 }, // ✅ Уменьшаем шрифт
        alignment: { vertical: "middle", horizontal: "center", wrapText: true },
        fill: { type: "pattern", pattern: "solid", fgColor: { argb: "CCCCCC" } }
    };

    // 📌 Добавляем заголовки
    sheet.columns = [
        { header: "№", key: "number", width: 5 },
        { header: "Имя", key: "name", width: 40 },
        { header: "Создана", key: "created", width: 15 },
        { header: "Автор", key: "author", width: 40 },
        { header: "Визы", key: "approvals", width: 40 },
        { header: "Исполнители", key: "exec", width: 40 },
        { header: "Выполнено", key: "yea", width: 40 },
        { header: "В работе", key: "no", width: 40 },
        { header: "Контроль", key: "controller", width: 40 }
    ];

    // ✅ Применяем стили к заголовкам
    sheet.getRow(1).eachCell(cell => {
        cell.style = headerStyle;
    });

    // 📌 Добавляем данные с переносами строк и авто-высотой
    json.forEach((doc, index) => {
        const approvalsText = `${doc.vizas.length ? doc.result : "Пустой маршрут"}\n${doc.stop || ""}\n${doc.sog_comm || ""}`;
        const row = sheet.addRow({
            number: index + 1,
            name: doc.Description,
            created: formatDate(doc.ДатаСоздания),
            author: doc.Создал_Key,
            approvals: approvalsText,

            // ✅ Строка с ФИО исполнителей
            exec: doc.processes.length
                ? doc.processes[0].Исполнители.map((ex) => ex.fio).join("\n")
                : "",

            // ✅ Первый комментарий истории, если есть
            yea:
                doc.processes.length &&
                doc.processes[0].Исполнители[0]?.history?.[0]?.Описание || "",

            // ✅ Комментарии с действиями (начинаются с "Исполнить" и не выполнены)
            no: doc.processes.length
                ? doc.processes[0].Исполнители[0]?.comments
                    ?.map((comment) =>
                        !comment.Executed && isActionToExecute(comment.Description)
                            ? `${comment.fio}. ${
                                comment.РезультатВыполнения?.length
                                    ? comment.РезультатВыполнения
                                    : "без комментария"
                            }`
                            : null
                    )
                    .filter(Boolean)
                    .join("\n")
                : "",

            // ✅ Комментарии по контролю (всё остальное)
            controller: doc.processes.length
                ? doc.processes[0].Исполнители[0]?.comments
                    ?.map((comment) =>
                        !isActionToExecute(comment.Description)
                            ? `${comment.fio}. ${
                                comment.РезультатВыполнения?.length
                                    ? comment.РезультатВыполнения
                                    : "без комментария"
                            } ${comment.Executed ? "Контроль пройден" : "Контроль не пройден или заявка не исполнена"}`
                            : null
                    )
                    .filter(Boolean)
                    .join("\n")
                : ""
        });
        // ✅ Применяем стили к ячейкам
        row.eachCell(cell => {
            cell.style = {
                font: { size: 9 }, // ✅ Уменьшаем шрифт текста в ячейках
                alignment: { vertical: "middle", horizontal: "center", wrapText: true } // ✅ Центрируем содержимое
            };
        });

        // ✅ Высчитываем высоту строки (по количеству переносов строк)
        const lineBreaks = approvalsText.split("\n").length;
        row.height = Math.max(15, lineBreaks * 15); // Минимум 15, но растягиваем
    });

    // 📌 Генерируем файл и скачиваем
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), `Документы_${new Date().toISOString().split("T")[0]}.xlsx`);
}
export default downloadXlsxFromJson